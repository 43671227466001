<script lang="ts">
    import Player from "@vimeo/player";
    import Vue from "vue";

    export default Vue.extend({
        name: "TutorialVideo",
        props: {
            videoVimeoId: {
                type: Number
            },
            title: {
                type: String,
                default: ""
            },
            bodyText: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                videoWidth: 400
            }
        },
        mounted() {
            let player = this.$refs["vimeoPlayer"];
            if (player) {
                player = new Player(player, {
                    id: this.videoVimeoId,
                    width: this.videoWidth
                });
            }
        }
    });
</script>

<style lang="scss" scoped>
    .video-container {
        margin-top: 0px;
        margin-bottom: 40px;
        width: 100%;
    }

    .video-title {
        margin-top: 20px;
        color: #026823;
        font-size: 1.4em;
        font-weight: bold;
    }

    .video-body-text {
        margin-top: 2px;
        margin-bottom: 20px;
        color: #555555;
        font-size: 1em;
        font-weight: normal;
    }
</style>
<style lang="scss">
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
        height: auto;  
    }

    .embed-container iframe, 
    .embed-container object, 
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
</style>

<template>
    <section class="video-container">
        <div class="containerr"> 
            <div class="card">
                <div class="row">
                    <div class="embed-container"  ref="vimeoPlayer"></div>
                </div>
                <div class="row">
                    <div class="video-title">{{ title }}</div>
                    <div class="video-body-text">{{ bodyText }}</div>
                </div>
            </div>
        </div>
    </section>
</template>
